@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg_img {
  background: url(../public/images/png/bg_img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

* {
  font-family: 'Ubuntu', sans-serif;
}

.scale {
  transition: all 1s;
  transform: scale(1.4);
}

.phone_img {
  background: url(../public/images/png/phone_img.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.login_drop_shadow {
  box-shadow: 0px 7px 10px 0px #0000001f;
}
.unlock_border {
  position: relative;
}
.unlock_border::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #a2f07b;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.lock_border_1,
.lock_border_2,
.lock_border_0 {
  position: relative;
}
.lock_border_1::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #ffd174;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.lock_border_0::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffd174;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.lock_border_2::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #ffd174;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
}
.lh_20 {
  line-height: 20px;
}
.lh_22 {
  line-height: 22px !important;
}
.lh_16 {
  line-height: 16px !important;
}
.logout_btn {
  border: 0;
  background-color: #ff2000;
  padding: 6px 13px;
  border-radius: 5px;
  color: white;
}
.bg_box_shadow {
  box-shadow: 0px -3px 8px #b8b8b8;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #eeeeee !important;
  padding: 12px 20px !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 16px !important;
  color: #00000080 !important;
}
.css-q8hpuo-MuiFormControl-root {
  height: 48px !important;
  overflow: hidden !important;
  border-radius: 12px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 10px !important;
}
.css-tzsjye {
  border-radius: 10px !important;
}
.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: #00000080 !important;
}
.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input::placeholder {
  color: #00000080 !important;
  opacity: 1 !important;
}
.css-igs3ac {
  border-color: transparent !important;
}
.css-qiwgdb {
  padding: 12px 12px !important;
  background-color: #eeeeee;
  font-family: 'Ubuntu', sans-serif !important;
  color: #00000080 !important;
}
.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-igs3ac {
  border: none !important;
}
.css-segi59:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-1uvydh2 {
  color: #00000080 !important;
  font-family: 'Ubuntu', sans-serif !important;
}
@media (hover: none) {
  .css-segi59:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}
@media (max-width: 580px) {
  .w_100 {
    width: 100%;
  }
  .Toastify__toast-body{
    font-size: 12px !important;
  }
}
@media (min-width: 768px) {
  .login_bg {
    background-image: url('../public/images/png/login-bg.png');
    background-size: cover;
    background-position: left;
    position: relative;
  }
  .login_bg::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000001f;
    left: 0;
    top: 0;
  }
}

@media (min-width: 580px) and (max-width: 768px) {
  .w_xsm {
    width: 46%;
  }

  .min_h_71 {
    min-height: 71vh;
  }

  .max_w_80 {
    max-width: 80%;
    margin: 0 auto;
  }

  .w_60 {
    width: 60% !important;
  }
}

@media (min-width: 1240px) and (max-width: 1400px) {
  .font_lg {
    font-size: 18px !important;
  }
}

.slick-slide {
  height: 50px !important;
}

.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 8px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #ffce32;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.min_h_calc {
  min-height: calc(680px - 140px) !important;
}
.box_shadow {
  box-shadow: 0px 4px 10px 0px #00000026;
}

.text_shadow {
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff,
    1px -1px #fff, -1px 1px #fff;
}
.width_calc {
  width: calc(100% - 29px) !important;
}
.width_calc_scroll::-webkit-scrollbar {
  width: 0px;
}
